import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule, routedComponents} from './app.routes';

import {AppComponent} from './app.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatButtonModule, MatIconModule, MatListModule, MatSelectModule, MatSidenavModule, MatToolbarModule} from '@angular/material';
import {AboutMeComponent} from './pages/about-me/about-me.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {ImprintComponent} from './pages/imprint/imprint.component';
import {DisclaimerComponent} from './pages/disclaimer/disclaimer.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    PortfolioComponent,
    ImprintComponent,
    DisclaimerComponent,
    routedComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
