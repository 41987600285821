import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  page = 1;
  maxPages = 1;
  pdf = null;
  pdfSrc = '/assets/pdf/' + this.pdf;

  portfolios = [{
    path: 1,
    title: 'kultur.werk.stadtneustadt b. coburg',
    src: 'SGK_045_kutlur_werk_stadt.pdf'
  }, {
    path: 2,
    title: 'Verwerfung oder die Eroberung eines Blickes',
    src: 'BR_SGK_Verwerfung_Felsen_Jena.pdf'
  }, {
    path: 3,
    title: 'Archivio Jodice',
    src: 'BUW_B_Archivio_Jodice_Napoli.pdf'
  }, {
    path: 4,
    title: 'Wettbewerb zum Ministeriumsneubau in der Wilhelmstraße in Berlin-Mitte',
    src: 'WB_SGK_Wilhelm_50.pdf'
  }];

  constructor() { }

  incrementPage(amount: number) {
    if (amount > 0 && this.page < this.maxPages) {
      this.page += amount;
    }
    if (amount < 0 && this.page > 1) {
      this.page += amount;
    }
  }

  changePdf(pdf) {
    this.pdf = pdf;
    this.page = 1;
    this.pdfSrc = '/assets/pdf/' + pdf.src;
  }

  countPages(e) {
    this.maxPages = e.numPages;
  }
}
