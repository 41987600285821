import {Component, OnInit} from '@angular/core';
import {PortfolioService} from '../../services/portfolio.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(
    public _portfolioService: PortfolioService,
    private _route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this._route.fragment.subscribe(fragment => {
      if (fragment) {
        const id = parseInt(fragment, 0) - 1;
        this._portfolioService.changePdf(this._portfolioService.portfolios[id]);
      } else {
        this._portfolioService.changePdf(this._portfolioService.portfolios[0]);
      }
    });
  }

}
