import {Component, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {PortfolioService} from './services/portfolio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'app';

  constructor(
    public _portfolioService: PortfolioService
  ) {}

}

