import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutMeComponent} from './pages/about-me/about-me.component';
import {DisclaimerComponent} from './pages/disclaimer/disclaimer.component';
import {ImprintComponent} from './pages/imprint/imprint.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';

export const routes: Routes = [
  {path: 'about-me', component: AboutMeComponent},
  {path: 'portfolio', component: PortfolioComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: '', redirectTo: '/about-me', pathMatch: 'full'},
  {path: '**', redirectTo: '/about-me'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

export const routedComponents = [];
